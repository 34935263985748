import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  heading: {
    color: '#D13528',
    borderBottom: '2px solid #D13528',
    paddingBottom: '10px',
    marginBottom: '20px',
  },
  select: {
    width: '100%',
    padding: '10px',
    marginBottom: '20px',
    fontSize: '16px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    backgroundColor: '#D13528',
    color: 'white',
    padding: '10px',
    textAlign: 'left',
  },
  td: {
    border: '1px solid #ddd',
    padding: '10px',
  },
  tr: {
    ':nth-child(even)': {
      backgroundColor: '#f2f2f2',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  pageButton: {
    margin: '0 5px',
    padding: '5px 10px',
    backgroundColor: '#D13528',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  loading: {
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '18px',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '18px',
  },
  button: {
    padding: '5px 10px',
    margin: '0 5px',
    backgroundColor: '#D13528',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  mobileContainer: {
    padding: '10px',
  },
  mobileHeading: {
    fontSize: '20px',
    marginBottom: '15px',
    color: '#D13528',
    borderBottom: '2px solid #D13528',
    paddingBottom: '10px',
  },
  mobileSelect: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    fontSize: '16px',
  },
  mobileCard: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '15px',
    marginBottom: '15px',
    backgroundColor: '#f9f9f9',
  },
  mobileCardItem: {
    marginBottom: '10px',
  },
  mobileLabel: {
    fontWeight: 'bold',
    marginRight: '5px',
  },
  mobileValue: {
    wordBreak: 'break-word',
  },
  mobileButton: {
    width: '100%',
    padding: '10px',
    marginTop: '10px',
    backgroundColor: '#D13528',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  mobilePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  mobilePageInfo: {
    textAlign: 'center',
    marginBottom: '10px',
  },
};

const ITEMS_PER_PAGE = 10;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const WorkflowStatusPage = () => {
  const [status, setStatus] = useState('1');
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const cacheRef = useRef({});
  const [user, setUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUser(storedUser);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchWorkflows = useCallback(async (status, page) => {
    const cacheKey = `${status}_${page}`;
    const cachedData = cacheRef.current[cacheKey];
    
    const shouldFetchFreshData = page === currentPage || !cachedData || Date.now() - cachedData.timestamp >= CACHE_DURATION;
  
    if (!shouldFetchFreshData) {
      setWorkflows(cachedData.data);
      setTotalItems(cachedData.totalItems);
      return;
    }
  
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Nenhum token de autenticação encontrado');
      }
  
      const response = await axios.get('https://wrt-paperdesk-f39673c41869.herokuapp.com/api/allworkflows', {
        params: { status, page, limit: ITEMS_PER_PAGE },
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
  
      if (response.data.success) {
        const fetchedWorkflows = response.data.response || [];
        const filteredWorkflows = fetchedWorkflows.filter(workflow => workflow.createdBy === user?.email);
        setWorkflows(filteredWorkflows);
        setTotalItems(filteredWorkflows.length);
  
        cacheRef.current[cacheKey] = {
          data: filteredWorkflows,
          totalItems: filteredWorkflows.length,
          timestamp: Date.now()
        };
      } else {
        throw new Error(response.data.responseDescription || 'Falha em obter os documentos pendentes de assinatura');
      }
    } catch (error) {
      setError(error.message || 'Ocorreu um erro ao obter os documentos pendentes de assinatura');
    } finally {
      setLoading(false);
    }
  }, [user, currentPage]);

  useEffect(() => {
    if (user) {
      fetchWorkflows(status, currentPage);
    }
  }, [status, currentPage, fetchWorkflows, user]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setCurrentPage(1);
  };

  const handleCancelWorkflow = async (workflowId) => {
    const reason = prompt("Por favor, escreva o motivo do cancelamento:");
    if (reason) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://wrt-paperdesk-f39673c41869.herokuapp.com/api/cancelworkflow', 
          { workflowID: workflowId, cancelReason: reason },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.data.success) {
          alert("Workflow cancelado!");
          const cacheKey = `${status}_${currentPage}`;
          delete cacheRef.current[cacheKey];
          fetchWorkflows(status, currentPage);
        } else {
          throw new Error(response.data.responseDescription || 'Falha ao cancelar o workflow');
        }
      } catch (error) {
        alert(`Falha ao cancelar o workflow: ${error.message}`);
      }
    }
  };

  const handleDownloadFiles = async (workflowId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://wrt-paperdesk-f39673c41869.herokuapp.com/api/downloadfiles', 
        { workflowID: workflowId },
        { 
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob'
        }
      );
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `workflow_${workflowId}_files.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      alert(`Falha ao transferir os ficheiros: ${error.message}`);
    }
  };

  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const renderMobileView = () => (
    <div style={styles.mobileContainer}>
      <h2 style={styles.mobileHeading}>Estado dos Processos</h2>
      <select 
        value={status} 
        onChange={handleStatusChange}
        style={styles.mobileSelect}
      >
        <option value="0">Em Rascunho</option>
        <option value="1">Iniciado</option>
        <option value="2">Concluído</option>
        <option value="3">Recusado</option>
        <option value="4">Expirado</option>
        <option value="5">Cancelado</option>
      </select>
      {loading ? (
        <div style={styles.loading}>A carregar...</div>
      ) : workflows.length > 0 ? (
        <>
          {workflows.map((workflow) => (
            <div key={workflow.workflowId} style={styles.mobileCard}>
              <div style={styles.mobileCardItem}>
                <span style={styles.mobileLabel}>ID:</span>
                <span style={styles.mobileValue}>{workflow.workflowId}</span>
              </div>
              <div style={styles.mobileCardItem}>
                <span style={styles.mobileLabel}>Nome:</span>
                <span style={styles.mobileValue}>{workflow.workflowName}</span>
              </div>
              <div style={styles.mobileCardItem}>
                <span style={styles.mobileLabel}>Criado Em:</span>
                <span style={styles.mobileValue}>{workflow.createdOn}</span>
              </div>
              <div style={styles.mobileCardItem}>
                <span style={styles.mobileLabel}>Estado:</span>
                <span style={styles.mobileValue}>{workflow.statusDescription}</span>
              </div>
              {status === '1' && (
                <button 
                  onClick={() => handleCancelWorkflow(workflow.workflowId)}
                  style={styles.mobileButton}
                >
                  Cancelar
                </button>
              )}
              {status === '2' && (
                <button 
                  onClick={() => handleDownloadFiles(workflow.workflowId)}
                  style={styles.mobileButton}
                >
                  Download
                </button>
              )}
            </div>
          ))}
          {totalPages > 1 && (
            <div style={styles.mobilePagination}>
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                style={styles.mobileButton}
              >
                Anterior
              </button>
              <div style={styles.mobilePageInfo}>
                Página {currentPage} de {totalPages}
              </div>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                style={styles.mobileButton}
              >
                Próxima
              </button>
            </div>
          )}
        </>
      ) : (
        <div>Nenhum processo encontrado no estado selecionado.</div>
      )}
    </div>
  );

  const renderDesktopView = () => (
    <div style={styles.container}>
      <h2 style={styles.heading}>Estado dos Processos</h2>
      <select 
        value={status} 
        onChange={handleStatusChange}
        style={styles.select}
      >
        <option value="0">Em Rascunho</option>
        <option value="1">Iniciado</option>
        <option value="2">Concluído</option>
        <option value="3">Recusado</option>
        <option value="4">Expirado</option>
        <option value="5">Cancelado</option>
      </select>
      {loading ? (
        <div style={styles.loading}>A carregar...</div>
      ) : workflows.length > 0 ? (
        <>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>ID</th>
                <th style={styles.th}>Nome</th>
                <th style={styles.th}>Criado Em</th>
                <th style={styles.th}>Estado</th>
                <th style={styles.th}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {workflows.map((workflow) => (
                <tr key={workflow.workflowId} style={styles.tr}>
                  <td style={styles.td}>{workflow.workflowId}</td>
                  <td style={styles.td}>{workflow.workflowName}</td>
                  <td style={styles.td}>{workflow.createdOn}</td>
                  <td style={styles.td}>{workflow.statusDescription}</td>
                  <td style={styles.td}>
                    {status === '1' && (
                      <button 
                        onClick={() => handleCancelWorkflow(workflow.workflowId)}
                        style={styles.button}
                      >
                        Cancelar
                      </button>
                    )}
                    {status === '2' && (
                      <button 
                        onClick={() => handleDownloadFiles(workflow.workflowId)}
                        style={styles.button}
                      >
                        Download
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div style={styles.pagination}>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => setCurrentPage(page)}
                  style={{
                    ...styles.pageButton,
                    backgroundColor: currentPage === page ? '#45a049' : '#D13528',
                  }}
                >
                  {page}
                </button>
              ))}
            </div>
          )}
        </>
      ) : (
        <div>Nenhum processo encontrado no estado selecionado.</div>
      )}
    </div>
  );

  return (
    <div style={styles.container}>
      {error && <div style={styles.error}>Erro: {error}</div>}
      {isMobile ? renderMobileView() : renderDesktopView()}
    </div>
  );
};

export default WorkflowStatusPage;