import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const styles = {
  hamburgerButton: {
    position: 'fixed',
    top: '10px',
    right: '10px',
    zIndex: 1001,
    background: '#D13528',
    border: 'none',
    fontSize: '30px',
    color: 'white',
    width: '45px',
    height: '45px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '1.5%',
    lineHeight: 1,
  },
  menuContainer: {
    position: 'fixed',
    top: 0,
    right: '-250px',
    width: '250px',
    height: '100%',
    transition: 'right 0.3s ease-in-out',
    zIndex: 1000,
  },
  menuContainerOpen: {
    right: 0,
  },
  menu: {
    width: '100%',
    height: '100%',
    backgroundColor: '#D13528',
    padding: '60px 20px 20px',
    boxShadow: '-5px 0 15px rgba(0,0,0,0.2)',
    overflowY: 'auto',
  },
  menuLink: {
    display: 'block',
    color: 'white',
    textDecoration: 'none',
    fontSize: '18px',
    padding: '10px 0',
  },
  logoutButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'left',
    color: 'white',
    fontSize: '18px',
    padding: '10px 0',
  },
};

const HamburgerMenu = ({ user, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      <button style={styles.hamburgerButton} onClick={toggleMenu}>
        {isOpen ? '✕' : '☰'}
      </button>
      <div style={{
        ...styles.menuContainer,
        ...(isOpen ? styles.menuContainerOpen : {})
      }}>
        <div style={styles.menu}>
          {(user.role === 'worker' || user.role === 'manager') && (
            <Link to="/sign-dashboard" style={styles.menuLink}>Os meus Documentos</Link>
          )}
          {user.role === 'manager' && (
            <>
              <Link to="/create-workflow" style={styles.menuLink}>Criar Processo</Link>
              <Link to="/workflow-status" style={styles.menuLink}>Estado dos Processos</Link>
            </>
          )}
          <button onClick={handleLogout} style={styles.logoutButton}>Logout</button>
        </div>
      </div>
    </>
  );
};

export default HamburgerMenu;