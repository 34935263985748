import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const styles = {
  container: {
    maxWidth: '400px',
    margin: '50px auto',
    padding: '30px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
  },
  heading: {
    borderBottom: '2px solid #D13528',
    paddingBottom: '10px',
    marginBottom: '20px',
    color: '#D13528',
    textAlign: 'center',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
    fontSize: '16px',
  },
  button: {
    backgroundColor: '#D13528',
    color: 'white',
    padding: '12px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
    transition: 'background-color 0.3s',
  },
  error: {
    color: 'red',
    marginTop: '10px',
    textAlign: 'center',
  },
};

const FrontLogo = styled.img`
    max-width: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const FrontLogoMobile = styled.img`
    max-width: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;

    @media (min-width: 769px) {
        display: none;
    }
`;

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.post('https://wrt-paperdesk-f39673c41869.herokuapp.com/api/login', { email, password });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      onLogin(response.data.user);
    } catch (error) {
      setError(error.response ? error.response.data.error : 'Ocorreu um erro durante o login');
    }
  };

  return (
    <div style={styles.container}>
      <FrontLogo src='https://i.ibb.co/d63DwbQ/Captura-de-ecr-2024-08-31-s-02-31-19.png' alt='PaperDesk Logo' />
      <FrontLogoMobile src='https://i.ibb.co/d63DwbQ/Captura-de-ecr-2024-08-31-s-02-31-19.png' alt='PaperDesk Logo Mobile' />
      <h2 style={styles.heading}>Login</h2>
      <form onSubmit={handleSubmit}>
        <div style={styles.formGroup}>
          <label htmlFor="email" style={styles.label}>Email</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="password" style={styles.label}>Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={styles.input}
          />
        </div>
        {error && <p style={styles.error}>{error}</p>}
        <button type="submit" style={styles.button}>Login</button>
      </form>
    </div>
  );
};

export default Login;