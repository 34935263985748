import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const styles = {
  container: {
    maxWidth: '600px',
    margin: '50px auto',
    padding: '40px',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease-in-out',
  },
  heading: {
    color: '#D13528',
    marginBottom: '20px',
    fontSize: '32px',
    fontWeight: 'bold',
  },
  headingSigned: {
    color: '#4CAF50',
    marginBottom: '20px',
    fontSize: '32px',
    fontWeight: 'bold',
  },
  message: {
    fontSize: '20px',
    marginBottom: '30px',
    color: '#333',
    lineHeight: '1.5',
  },
  button: {
    backgroundColor: '#D13528',
    color: 'white',
    padding: '14px 28px',
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    boxShadow: '0 4px 6px rgba(209, 53, 40, 0.25)',
  },
  icon: {
    fontSize: '80px',
    color: '#4CAF50',
    marginBottom: '20px',
    animation: 'scaleUp 0.5s ease-out',
  },
  '@keyframes scaleUp': {
    '0%': { transform: 'scale(0)' },
    '80%': { transform: 'scale(1.1)' },
    '100%': { transform: 'scale(1)' },
  },
};

const SignedDocument = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleNavigateToDocuments = () => {
    navigate('/workflow-status');
  };

  return (
    <div style={{
      ...styles.container,
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
    }}>
      <div style={styles.icon}>✅</div>
      <h2 style={styles.headingSigned}>Documento Assinado!</h2>
      <button 
        onClick={handleNavigateToDocuments} 
        style={styles.button}
        onMouseEnter={(e) => e.target.style.backgroundColor = '#66BB6A'}
        onMouseLeave={(e) => e.target.style.backgroundColor = '#4CAF50'}
      >
        Voltar aos meus Documentos
      </button>
    </div>
  );
};

export default SignedDocument;