import React, { useState, useEffect } from 'react';
import axios from 'axios';

const styles = {
  form: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '30px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
    fontSize: '16px',
  },
  select: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    fontSize: '16px',
  },
  button: {
    backgroundColor: '#D13528',
    color: 'white',
    padding: '12px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
    transition: 'background-color 0.3s',
  },
  fileInput: {
    marginBottom: '10px',
  },
  heading: {
    borderBottom: '2px solid #D13528',
    paddingBottom: '10px',
    marginBottom: '20px',
    color: '#D13528',
  },
  fileList: {
    listStyle: 'none',
    padding: 0,
  },
  fileItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
  },
  removeButton: {
    backgroundColor: '#ff4d4d',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    cursor: 'pointer',
  },
  signerSection: {
    backgroundColor: '#f9f9f9',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  addSignerButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 20px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  removeSignerButton: {
    backgroundColor: '#ff4d4d',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    cursor: 'pointer',
    marginTop: '10px',
  },
};

const CreateWorkflow = () => {
  const [formData, setFormData] = useState({
    workflowName: '',
    processID: '',
    createdBy: '',
    expirationTime: '',
    docsCallbackUrl: 'https://wrt-paperdesk-f39673c41869.herokuapp.com/api/signingdesk-callback',
    documents: [],
    hops: [
      {
        Collaborators: {
          Collaborators_type: '1', 
          Collaborators_userID: '',
          Collaborators_name: '',
          Collaborators_email: '',
          Collaborators_phone: '',
          Collaborators_companyID: '934efea0-75f1-4121-85f0-eb065165c7bd',
          Collaborators_action: 'SIGNER',
          Collaborators_signatureType: 'GUESTPLATFORM', 
          Collaborators_isNotification: true,
          Collaborators_isReadCompulsory: true,
          Collaborators_isReceiveSignedDocsAtTheEnd: true,
          Collaborators_language: 'pt-PT',
          Collaborators_redirectURL: 'https://wrt-paperdesk-f39673c41869.herokuapp.com'
        },
        Designers: []
      }
    ]
  });

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.email) {
      setFormData(prevState => ({
        ...prevState,
        createdBy: storedUser.email,
        processID: `PROC_${Date.now()}`
      }));
    }

    // Utilizadores
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://wrt-paperdesk-f39673c41869.herokuapp.com/api/usersbycompany', {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.success) {
          setUsers(response.data.response);
        } else {
          console.error('Failed to fetch users:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);


  const handleInputChange = (e, index, field, subfield) => {
    const { name, value } = e.target;
    setFormData(prevState => {
      const updatedFormData = { ...prevState };
      if (field === 'hops') {
        updatedFormData.hops[index].Collaborators[name] = value;
        
        // Automatically set the correct signature type when signer type changes
        if (name === 'Collaborators_type') {
          updatedFormData.hops[index].Collaborators.Collaborators_signatureType = 
            value === '0' ? 'PLATFORM' : 'GUESTPLATFORM';
        }
      } else {
        updatedFormData[name] = value;
      }
      return updatedFormData;
    });
  };

  const handleEmailChange = (e, index) => {
    const email = e.target.value;
    const user = users.find(u => u.userEmail.toLowerCase() === email.toLowerCase());
    
    setFormData(prevState => {
      const updatedFormData = { ...prevState };
      updatedFormData.hops[index].Collaborators.Collaborators_email = email;
      
      if (user) {
        updatedFormData.hops[index].Collaborators.Collaborators_userID = user.usersID;
        updatedFormData.hops[index].Collaborators.Collaborators_name = user.username;
      } else {
        updatedFormData.hops[index].Collaborators.Collaborators_userID = '';
        updatedFormData.hops[index].Collaborators.Collaborators_name = '';
      }
      
      return updatedFormData;
    });
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    const maxFiles = 5;
    const maxFileSize = 10 * 1024 * 1024; // 10MB max file size
    const remainingSlots = maxFiles - formData.documents.length;
    const filesToProcess = files.slice(0, remainingSlots);
  
    filesToProcess.forEach(file => {
      if (file.size > maxFileSize) {
        alert(`O ficheiro ${file.name} é demasiado grande. O tamanho máximo do ficheiro é 10MB.`);
        return;
      }
  
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Content = e.target.result.split(',')[1];
        const timestamp = Date.now().toString(36);
        const fileNamePart = file.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '').slice(0, 20);
        const docId = `DOC_${timestamp}_${fileNamePart}`.slice(0, 30);
  
        setFormData(prevState => ({
          ...prevState,
          documents: [
            ...prevState.documents,
            {
              fileName: file.name,
              docId: docId,
              Base64PdfContents: base64Content
            }
          ]
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  const removeFile = (index) => {
    setFormData(prevState => {
      const updatedFormData = { ...prevState };
      updatedFormData.documents.splice(index, 1);
      return updatedFormData;
    });
  };

  const addSigner = () => {
    setFormData(prevState => ({
      ...prevState,
      hops: [
        ...prevState.hops,
        {
          Collaborators: {
            Collaborators_type: '1',
            Collaborators_userID: '',
            Collaborators_name: '',
            Collaborators_email: '',
            Collaborators_phone: '',
            Collaborators_companyID: '934efea0-75f1-4121-85f0-eb065165c7bd',
            Collaborators_action: 'SIGNER',
            Collaborators_signatureType: 'GUESTPLATFORM',
            Collaborators_isNotification: true,
            Collaborators_isReadCompulsory: true,
            Collaborators_isReceiveSignedDocsAtTheEnd: true,
            Collaborators_language: 'pt-PT',
            Collaborators_redirectURL: 'https://wrt-paperdesk-f39673c41869.herokuapp.com'
          },
          Designers: []
        }
      ]
    }));
  };

  const removeSigner = (index) => {
    setFormData(prevState => {
      const updatedHops = [...prevState.hops];
      updatedHops.splice(index, 1);
      return { ...prevState, hops: updatedHops };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (formData.documents.length === 0) {
      alert("Por favor, selecione pelo menos um documento.");
      return;
    }
  
    if (formData.hops.length === 0) {
      alert("Por favor, identifique pelo menos um signatário.");
      return;
    }
  
    setIsLoading(true);
  
    try {
      const updatedFormData = {...formData};
  
      const signatureWidth = 165;
      const signatureHeight = 55;
      const pageWidth = 595;
      const pageHeight = 842;
      const marginX = 50;
      const marginY = 50;
  
      const signaturesPerRow = Math.floor((pageWidth - 2 * marginX) / signatureWidth);
  
      updatedFormData.hops = formData.hops.map((hop, hopIndex) => {
        const updatedHop = {...hop};
        
        updatedHop.Designers = updatedFormData.documents.map((doc) => {
          const row = Math.floor(hopIndex / signaturesPerRow);
          const col = hopIndex % signaturesPerRow;
  
          return {
            docId: doc.docId,
            pageNumber: 1,
            fieldWidth: signatureWidth,
            fieldHeight: signatureHeight,
            fieldX: marginX + col * signatureWidth,
            fieldY: pageHeight - (marginY + (row + 1) * signatureHeight)
          };
        });
  
        if (updatedHop.Collaborators.Collaborators_type === '1') {
          if (!updatedHop.Collaborators.Collaborators_name || !updatedHop.Collaborators.Collaborators_email || !updatedHop.Collaborators.Collaborators_phone) {
            throw new Error('Nome, Email e Telemóvel são necessários para utilizadores convidados');
          }
          if (updatedHop.Collaborators.Collaborators_name.trim().split(/\s+/).length < 2) {
            throw new Error('Por favor, insira o nome e apelido para utilizadores convidados');
          }
          updatedHop.Collaborators.Collaborators_signatureType = 'GUESTPLATFORM';
        } else if (!updatedHop.Collaborators.Collaborators_userID) {
          throw new Error('É necessário um ID de Utilizador para utilizadores registados');
        }
  
        return updatedHop;
      });
  
      if (updatedFormData.documents.length > 0 && updatedFormData.hops.length > 0) {
        const firstDocument = updatedFormData.documents[0];
        const firstSigner = updatedFormData.hops[0].Collaborators;
        const fileName = firstDocument.fileName.split('.')[0];
        const signerId = firstSigner.Collaborators_type === '0' ? firstSigner.Collaborators_userID : firstSigner.Collaborators_name;
        updatedFormData.workflowName = `eSIGNATURE_${fileName}_${signerId}`;
      }
  
      const response = await fetch('https://wrt-paperdesk-f39673c41869.herokuapp.com/api/createworkflow', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(updatedFormData),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Erro HTTP! status: ${response.status}, mensagem: ${errorText}`);
      }
  
      const result = await response.json();
  
      if (result.success) {
        alert('Processo criado com sucesso!');
      } else {
        throw new Error(result.responseDescription || 'Falha na comunicação com o servidor');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert(`Ocorreu um erro ao processar o pedido: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <h2 style={styles.heading}>Criar Processo</h2>

      <div style={styles.formGroup}>
        <label htmlFor="expirationTime" style={styles.label}>Validade</label>
        <input
          type="datetime-local"
          id="expirationTime"
          name="expirationTime"
          value={formData.expirationTime}
          onChange={(e) => handleInputChange(e)}
          style={styles.input}
        />
      </div>

      {/* Documents section */}
      <div style={styles.formGroup}>
        <h3 style={styles.heading}>Documentos</h3>
        <input
          type="file"
          accept=".pdf"
          multiple
          onChange={handleFileUpload}
          style={styles.fileInput}
          disabled={formData.documents.length >= 5}
        />
        <ul style={styles.fileList}>
          {formData.documents.map((doc, index) => (
            <li key={index} style={styles.fileItem}>
              <span>{doc.fileName}</span>
              <button type="button" onClick={() => removeFile(index)} style={styles.removeButton}>
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Signers section */}
      <div style={styles.formGroup}>
        <h3 style={styles.heading}>Signatários</h3>
        <button type="button" onClick={addSigner} style={styles.addSignerButton}>
          Adicionar Signatário
        </button>
        {formData.hops.map((hop, index) => (
          <div key={index} style={styles.signerSection}>
            <h4>Signatário n.º {index + 1}</h4>
            <div style={styles.formGroup}>
              <label style={styles.label}>Tipo de Utilizador</label>
              <select
                name="Collaborators_type"
                value={hop.Collaborators.Collaborators_type}
                onChange={(e) => handleInputChange(e, index, 'hops', 'Collaborators')}
                style={styles.select}
              >
                <option value="1">Default</option>
                <option value="0">Registado</option>
              </select>
            </div>

            {hop.Collaborators.Collaborators_type === '0' ? (
              <div style={styles.formGroup}>
                <label style={styles.label}>Email do Utilizador</label>
                <input
                  type="email"
                  name="Collaborators_email"
                  placeholder="Email do utilizador registado"
                  value={hop.Collaborators.Collaborators_email}
                  onChange={(e) => handleEmailChange(e, index)}
                  required
                  style={styles.input}
                />
                {hop.Collaborators.Collaborators_userID && (
                  <small style={{ color: '#666', marginTop: '5px', display: 'block' }}>
                    Utilizador encontrado: {hop.Collaborators.Collaborators_name}
                  </small>
                )}
                {!hop.Collaborators.Collaborators_userID && hop.Collaborators.Collaborators_email && (
                  <small style={{ color: 'red', marginTop: '5px', display: 'block' }}>
                    Utilizador não encontrado. Confirme se este email está registado na SigningDesk.
                  </small>
                )}
              </div>
            ) : (
              <>
              <div style={styles.formGroup}>
                <label style={styles.label}>Nome Completo</label>
                <input
                  type="text"
                  name="Collaborators_name"
                  placeholder="Nome e Sobrenome"
                  value={hop.Collaborators.Collaborators_name}
                  onChange={(e) => handleInputChange(e, index, 'hops', 'Collaborators')}
                  required
                  style={styles.input}
                />
                <small style={{ color: '#666', marginTop: '5px', display: 'block' }}>
                  Por favor, insira o nome e sobrenome
                </small>
              </div>

                <div style={styles.formGroup}>
                  <label style={styles.label}>Email</label>
                  <input
                    type="email"
                    name="Collaborators_email"
                    placeholder="Email"
                    value={hop.Collaborators.Collaborators_email}
                    onChange={(e) => handleInputChange(e, index, 'hops', 'Collaborators')}
                    required
                    style={styles.input}
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>N.º de Telemóvel</label>
                  <input
                    type="tel"
                    name="Collaborators_phone"
                    placeholder="Telemóvel"
                    value={hop.Collaborators.Collaborators_phone}
                    onChange={(e) => handleInputChange(e, index, 'hops', 'Collaborators')}
                    required
                    style={styles.input}
                  />
                  <small style={{ color: '#666', marginTop: '5px', display: 'block' }}>
                  Por favor, inclua o indicativo (PT: +351)
                  </small>
                </div>
              </>
            )}

            <div style={styles.formGroup}>
              <label style={styles.label}>Ação</label>
              <select
                name="Collaborators_action"
                value={hop.Collaborators.Collaborators_action}
                onChange={(e) => handleInputChange(e, index, 'hops', 'Collaborators')}
                style={styles.select}
              >
                <option value="SIGNER">Assinar Doc.</option>
              </select>
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>Tipo de Assinatura</label>
              <select
                name="Collaborators_signatureType"
                value={hop.Collaborators.Collaborators_signatureType}
                onChange={(e) => handleInputChange(e, index, 'hops', 'Collaborators')}
                style={styles.select}
              >
                {hop.Collaborators.Collaborators_type === '0' ? (
                  <option value="PLATFORM">Plataforma</option>
                ) : (
                  <option value="GUESTPLATFORM">Plataforma (Guest)</option>
                )}
              </select>
            </div>

            <button type="button" onClick={() => removeSigner(index)} style={styles.removeSignerButton}>
              Remover Signatário
            </button>
          </div>
        ))}
      </div>

      <button type="submit" style={styles.button} disabled={isLoading}>
        {isLoading ? 'A processar...' : 'Iniciar Processo'}
      </button>
    </form>
  );
};

export default CreateWorkflow;