import React, { useState, useEffect } from 'react';
import axios from 'axios';

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  heading: {
    color: '#D13528',
    borderBottom: '2px solid #D13528',
    paddingBottom: '10px',
    marginBottom: '20px',
  },
  workflowList: {
    listStyle: 'none',
    padding: 0,
  },
  workflowItem: {
    backgroundColor: '#f9f9f9',
    padding: '15px',
    marginBottom: '10px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  button: {
    backgroundColor: '#D13528',
    color: 'white',
    padding: '8px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.3s',
    marginTop: '10px',
  },
  disabledButton: {
    backgroundColor: '#cccccc',
    color: '#666666',
    cursor: 'not-allowed',
  },
  loading: {
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '18px',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '18px',
  },
};

const SignDashboard = () => {
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    setUser(storedUser);
    fetchWorkerWorkflows();
  }, []);

  const fetchWorkerWorkflows = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://wrt-paperdesk-f39673c41869.herokuapp.com/api/worker-workflows', {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.data.success) {
        setWorkflows(response.data.response);
      } else {
        throw new Error('Falha em obter os documentos pendentes de assinatura');
      }
    } catch (err) {
      setError('Ocorreu um erro ao obter os documentos pendentes de assinatura');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSign = (signUrl) => {
    window.location.href = signUrl;
  };

  if (loading) return <div style={styles.loading}>A carregar...</div>;
  if (error) return <div style={styles.error}>Erro: {error}</div>;

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Olá, {user ? user.username : 'Wortener'}!</h2>
      <h3>Tem sessão iniciada com <u>{user.email}</u>. <br></br>Consulte os seus documentos pendentes de assinatura abaixo.</h3>
      
      {user.role === 'manager' && (
        <p>Como é {user.role} tem também disponíveis as funcionalidades de <a href="/create-workflow">criar um novo processo de assinatura</a> ou de <a href="/workflow-status">consultar o estado dos processos geridos por si</a>.</p>
      )}

      {workflows.length === 0 ? (
        <p>Sem processos em aberto!</p>
      ) : (
        <ul style={styles.workflowList}>
          {workflows.map((workflow) => (
            <li key={workflow.workflowId} style={styles.workflowItem}>
              <h4>{workflow.workflowName}</h4>
              <p>Validade: {new Date(workflow.expiration).toLocaleString()}</p>
              <button 
                onClick={() => handleSign(workflow.signUrl)} 
                style={{
                  ...styles.button,
                  ...(workflow.canSign ? {} : styles.disabledButton)
                }}
                disabled={!workflow.canSign}
              >
                {workflow.canSign ? 'Assinar' : 'A aguardar outra assinatura...'}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SignDashboard;