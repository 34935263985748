import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import Login from './components/Login';
import CreateWorkflow from './components/CreateWorkflow';
import WorkflowStatusPage from './components/WorkflowStatusPage';
import SignDashboard from './components/SignDashboard';
import SignedDocument from './components/SignedDocument';
import HamburgerMenu from './components/HamburgerMenu';

const popupStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    zIndex: 1000,
    overflowY: 'auto',
    padding: '20px 0',
  },
  content: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '500px',
    maxHeight: '90vh',
    overflowY: 'auto',
    margin: 'auto',
  },
  closeButton: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#D13528',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '20px',
  },
};

const navStyles = {
  nav: {
    backgroundColor: '#D13528',
    padding: '10px 0',
  },
  navContainer: {
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navLinks: {
    display: 'flex',
    gap: '20px',
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '16px',
    padding: '5px 10px',
    borderRadius: '4px',
  },
  logoutButton: {
    backgroundColor: 'transparent',
    color: 'white',
    border: '2px solid white',
    padding: '5px 15px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

function App() {
  const [user, setUser] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <Router>

      <div className="app">
        {!user && isPopupVisible && (
          <div style={popupStyles.overlay}>
            <div style={popupStyles.content}>
              <button style={popupStyles.closeButton} onClick={togglePopup}>Fechar</button>
              <h2>Onde estou?</h2>
              <p>Este espaço procura recriar a maneira de interação em MPV dos Trabalhadores e Gestores de RH das lojas com a API da Singingdesk, através de um módulo a que chamei <em>PaperDesk</em>.</p>
              <p>Cada pessoa (que recebeu o email) tem o seu utilizador: <br></br>email-WRT | primeironome123 (role manager)</p> 
              <p>Existem ainda os seguintes utilizadores de teste: <br></br>ext1@bidoeira.com | ext123 (worker1) <br></br> ext2@bidoeira.com | ext456 (worker2)</p>
              <p>Na <b>Dashboard do Manager</b>, é possível criar um processo de assinatura — associando-o a um worker (Guest na SingingDesk) pelo seu email. É ainda possivel confirmar o estado dos processos por si criados.</p>
              <p>Já na <b>Dashboard do Trabalhador</b>, ficam disponíveis os seus documentos por assinar associados, que reencaminham para a plataforma da SigningDesk.</p>
              <p>De notar que faltam, naturalmente, várias idiossincrasias, pretendendo com isto apenas tornar mais lúdico os fluxogramas construídos e as idealizações de processos.</p>
              <p>Qualquer questão, podem endereçar a <a href="mailto=francisco@bidoeira.com">francisco@bidoeira.com</a>.</p>
            </div>
          </div>
        )}

        {user && (
          <>
            {isMobile ? (
              <HamburgerMenu user={user} handleLogout={handleLogout} />
            ) : (
              <nav style={navStyles.nav}>
                <div style={navStyles.navContainer}>
                  <div style={navStyles.navLinks}>
                    {(user.role === 'worker' || user.role === 'manager') && (
                      <Link to="/sign-dashboard" style={navStyles.navLink}>Os meus Documentos</Link>
                    )}
                    {user.role === 'manager' && (
                      <>
                        <Link to="/create-workflow" style={navStyles.navLink}>Criar Processo</Link>
                        <Link to="/workflow-status" style={navStyles.navLink}>Estado dos Processos</Link>
                      </>
                    )}
                  </div>
                  <button onClick={handleLogout} style={navStyles.logoutButton}>Logout</button>
                </div>
              </nav>
            )}
          </>
        )}
        <div className="content">
          <Routes>
            <Route 
              path="/sign-dashboard" 
              element={user && (user.role === 'worker' || user.role === 'manager') ? <SignDashboard /> : <Navigate to="/login" />} 
            />
            <Route
              path="/signed-document"
              element={<SignedDocument />}
            />
            <Route 
              path="/login" 
              element={user ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} 
            />
            <Route 
              path="/create-workflow" 
              element={user && user.role === 'manager' ? <CreateWorkflow /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/workflow-status" 
              element={user && user.role === 'manager' ? <WorkflowStatusPage /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/" 
              element={
                user 
                  ? user.role === 'manager'
                    ? <Navigate to="/sign-dashboard" />
                    : <Navigate to="/sign-dashboard" />
                  : <Navigate to="/login" />
              } 
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
